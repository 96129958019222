import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Snackbar, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataContextProvider, SetUpdateHookContextProvider, PathHook } from '../hooks/datatabs';
import { Nullable, TabSpecs, DueDiligenceParsed, TabsInfo } from '../types/types';
import getContextData from '../utils/dataforcontext';
import DueDiligenceHeader from '../containers/duediligences/pageheader';
import { DdService } from '../services/dds';
import ErrorPageManager from '../components/Error/errorpagemanager';
import ServiceError from '../services/errors';
import IdRouter from "./idrouter";
import DynamicTabs from '../components/layout/tabs/dynamictabs';
import DynamicTabsRouter from './dynamictabsrouter';

// Tabs
import DdSummary from '../containers/duediligences/pages/summary';
import DdAnalysis from '../containers/duediligences/pages/analysis';
import DdDeveloper from '../containers/duediligences/pages/developer';
import DdAdmin from '../containers/duediligences/pages/admin';
import { DdDocumentList } from '../containers/duediligences/listing/dddocumentlist';
import DdElementDetails from '../containers/duediligences/pages/analysis/questiondetail';
import DdValidationItemList from '../containers/duediligences/pages/managerview/DdValidationItemList';
import DdMeetings from '../containers/duediligences/pages/meetings';
import DdReports from '../containers/duediligences/pages/reports';
import Parameters from '../containers/duediligences/pages/parameters/parameters';
import DdAuditTrail from '../containers/duediligences/pages/audittrail/ddaudittrail';

function DueDiligenceRouter(): ReactElement {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [data, setData] = useState<Nullable<DueDiligenceParsed>>(null);
  const [tabsInfo, setTabsInfo] = useState<Nullable<TabsInfo>>(null);
  const ddService = new DdService();
  const [error, setError] = useState<Nullable<ServiceError>>(null);
  const [update, setUpdate] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const ddTabSpecs: TabSpecs = [
    { id: 'summary', label: t('dds.tabs.summary.label'), component: <DdSummary /> },
    { id: 'analysis', label: t('dds.tabs.analysis.label'), component: <IdRouter index={<DdAnalysis />}><DdElementDetails /></IdRouter> },
    { id: 'meetings', label: t('dds.tabs.meetings.label'), component: <DdMeetings /> },
    { id: 'reports', label: t('dds.tabs.reports.label'), component: <DdReports /> },
    { id: 'documents', label: t('dds.tabs.documents.label'), component: <DdDocumentList /> },
    { id: 'parameters', label: t('dds.tabs.parameters.label'), component: <Parameters /> }, // before, component: DdParticipants
    { id: 'auditTrail', label: 'AuditTrail', component: <DdAuditTrail /> },
    // {path: 'messages', label: 'Messages', component: Messages},
    { id: 'developer', label: t('dds.tabs.developer.label'), component: <DdDeveloper /> },
    { id: 'admin', label: t('dds.tabs.admin.label'), component: <DdAdmin /> },
    { id: 'managerView', label: t('dds.tabs.managerView.label'), component: <DdValidationItemList /> }
  ];

  const getTabsInfo = () => {
    id && ddService.getTabsInfo(id)
      .then((apiResp) => {
        if (apiResp.data == null) {
          setTabsInfo(null);
        } else {
          // Filter any unknown tabs so that the tabsInfo kept only contains known tabs (therefore the tab index can be correct)
          const actualTabs = apiResp.data.tabs.filter((tabInfo) => {
            const matchingTabSpecs = ddTabSpecs.filter(tabSpec => (tabSpec.id === (tabInfo?.id || 'summary')));

            return matchingTabSpecs.length > 0;
          });
          const actualTabsInfo = { tabs: actualTabs };
          setTabsInfo(actualTabsInfo);
        }
      })
      .catch((err) => {
        setErrorMsg(`${err.response?.status}: ${err.message}`);
        setSnackbarOpen(true);
      });
  };

  useEffect(() => {
    if (update && id) {
      data && setData(null);
      tabsInfo && setTabsInfo(null);

      ddService.getEntity(id)
        .then((apiResp) => {
          if (apiResp.data) {
            setData(getContextData(apiResp.data));
            getTabsInfo();
          }
          setUpdate(false);
        })
        .catch((err) => {
          setError(ServiceError.ensureServiceError(err));
          setUpdate(false);
        });
    }
  }, [update, id]);

  const pathName = `${data?.dueDiligence?.auditedEntity?.name  } - ${  data?.dueDiligence?.type  }${data?.dueDiligence?.expectedValidationDate ? ` - ${  data?.dueDiligence?.expectedValidationDate}`: ` - ${  data?.dueDiligence?.validationDate}`}`;
  PathHook(pathName.replace(`- undefined`, ''));

  const dd = (data && data.dueDiligence) ? data.dueDiligence : null;

  return (
    <>
      <SetUpdateHookContextProvider value={setUpdate}>
        <Grid container spacing={2}>
          { error ? <ErrorPageManager error={error} /> :
            <DataContextProvider value={data}>
              <Grid item xs={12}>
                <DueDiligenceHeader entity={dd} />
              </Grid>
              { tabsInfo &&
                <>
                  <Grid item xs={12}>
                    <DynamicTabs tabsInfo={tabsInfo} tabSpecs={ddTabSpecs} />
                  </Grid>
                  <Grid item xs={12}>
                    <DynamicTabsRouter tabsInfo={tabsInfo} tabSpecs={ddTabSpecs} />
                  </Grid>
                </>
              }
            </DataContextProvider>
          }
        </Grid>
      </SetUpdateHookContextProvider>
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default DueDiligenceRouter;
