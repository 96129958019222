import React, { createContext, ReactElement, useContext, useMemo } from "react";
import { ParamFilter, ApiFilter } from "@deecision/deeligenz-interfaces";
import { FilterParamsManager } from "../../entity/list";
import GenericFilters from "../../filters/filters";
import { getSearchParamsContext } from "../../params";

export const ParamFilterContext = createContext<ParamFilter[] | null>(null);

function AuditTrailFilters(props: { filterParamsManager: FilterParamsManager, apiFilters?: ApiFilter[] }): ReactElement {
  const searchParamsAccess = getSearchParamsContext();
  const context = useContext(ParamFilterContext);
  const filters = useMemo(() => {
    const baseFilters: Array<ParamFilter> = props.apiFilters
      ? props.apiFilters.map(filter => (
        { id: filter.value, type: 'multiSelect', label: filter.label, values: filter.elements }
      ))
      : [];

    if (context) {
      context.forEach((filter) => {
        baseFilters.push(filter);
      });
    }

    baseFilters.push({
      id: 'actorType',
      type: 'multiSelect',
      label: 'Type',
      values: [{ label: 'user', value: 'user' }, { label: 'system', value: 'system' }]
    });
    baseFilters.push({
      id: 'scope',
      type: 'multiSelect',
      label: 'Scope',
      values: [
        { label: 'Due Diligence', value: 'due_diligence' },
        { label: 'Audited Entity', value: 'audited_entity' }
      ]
    });

    return baseFilters;
  }, [context, props.apiFilters]);
  
  const filtersKey = searchParamsAccess;

  return (<GenericFilters key={filtersKey.getFilters().join(',')} filters={filters} filterParamsManager={props.filterParamsManager} />);
}

export default AuditTrailFilters;